.policy-profile-column{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    overflow: auto;
}


.policy-profile-column > div{
    overflow: visible;
}

input#issued_at , input#expires_at {
    overflow: visible;
}

@media screen and (max-width: 480px) {
    .policy-profile-column{
        padding-left: 1rem;
        width:100% ;
        overflow: scroll;
    }
}