.leave-form-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-right: 10%;
    margin-left: auto;
}

.leave-form-button > a > button {
    position: sticky;
    top: 0;
}



form::-webkit-scrollbar {
    display: none;
  }

.add-cyber-form {
    display: flex;
    margin-bottom: auto;
    margin-left: .5rem;
    margin-right: .5rem;
    padding: .5rem;
    border-radius: 15px;
    background-color: rgb(2, 7, 32);
    overflow: auto;
}

form {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    /* overflow-x: scroll; */
    overflow-y: scroll;
}

form::backdrop {
    background-image: linear-gradient(
      45deg,
      magenta,
      rebeccapurple,
      dodgerblue,
      green
    );
    opacity: 0.50;
  }

label {
    width: max-content;
    margin: .5rem;
    font-weight: 400;
    font-size: 1.5rem;
    color: white;
}

.form-submit-btn{
    margin: 0.5rem;
    margin-top: 1rem;
    padding: 0.5rem, 1rem;
    border-style: hidden;
    border-radius: 2em;
    cursor: pointer;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    background-color: rgba(5, 127, 158, 0.87);
}

.form-submit-btn:hover {
    background-color: rgb(21, 4, 46);
}

form > input {
    background-color: rgb(2, 1, 24);
    color: white;
    color-scheme: dark;
    height: 2rem;
    font-weight: 350;
    font-size: 1.5rem;
    margin: .5rem;
}


form > select {
    background-color: rgb(2, 1, 24);
    color: white;
    height: 2rem;
    font-weight: 350;
    font-size: 1.5rem;
    margin: .5rem;
}

@media screen and (max-width: 480px) {
    *{
        font-size: 1rem;
        overflow: auto;
      }

      .leave-form-button {
        margin-top: 0;
      }

      form {
        width: 90%;
      }

    label {
        width: max-content;
        margin: .3rem;
        margin-left: 1rem;
        font-weight: 400;
        font-size: 1rem;
        color: white;
    }

    dialog form label {
        color: white;
    }


    form > input {
        height: 2.5rem;
        font-weight: 400;
        font-size: 1.2rem;
        margin: .3rem;
        margin-left: 1rem;
    }

    .leave-form-button {
        display: flex;
        position: sticky;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin:auto;
    }
}