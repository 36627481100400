 
  .container {
    padding: .5rem;
    display: flex;
    flex-direction: column;
    flex-basis: 150%;
    
  }
  
  #header-row {
    position: sticky;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    align-items: center;
    justify-content: center;
    width: 90vw;
    height: 5vmin;
    
  }
  
  
  #body-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 75dvh;
    overflow: auto;
    margin-top: 0;
    justify-content: left;
    
  }
  
  #body-row::-webkit-scrollbar {
    display: none;
  }
  
  #body-row > div.nav-col {
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
    justify-content: center;
    margin-bottom: auto;
    margin-top:1rem;
  }
  
  #body-row > div.content-col {
    flex-basis: 70%;
    display: flex;  
    flex-direction: column;
    justify-content: center;
  
  }
  
  div {
    overflow: hidden;
  }
  
  a {
    text-decoration: none;
    color: antiquewhite;
  }
  
  .ms-3 {
    margin-right: 3vw;
    margin-left: 3vw;
  }
  
  .ms-5 {
    margin-right: 5vw;
    margin-left: 5vw;
  }
  
  .mt-3 {
    margin-top: 3dvh;
    margin-bottom: 3dvh;
  }
  
  .mt-5 {
    margin-top: 5dvh;
    margin-bottom: 5dvh;
  }
  
  div.Name-Col {
    display: flex;
    justify-items: left;
    float: left;
    margin-right: auto;
  }
  
  div.Name-Col > h1 {
    display: flex;
    flex-direction: column;
    font-size: 4vmin;
    flex-basis: 100%;
    margin-top: auto;
    margin-bottom: 0;
  }
  
  img.App-Logo{
    display: block;
    height:5vmin;
    float: left;
  }
  
  div.Search-Col {
    justify-content: center;
    margin-left: auto;
    min-width: 30dvw;
    border-radius: 7%;
  }

  div.Search-Col > div > input{
    background-color: white;
    width: 28dvw;
  }
  
  div.Profile-Col {
    justify-content: flex-end;
  }
  
  div.list-group {
    display: flex;
    flex-direction: column;
    background-color: rgb(1, 1, 22);
    padding: 1rem;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  div.list-group > a > div.list-group-item{
    width: 20vw;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(3, 11, 49);
    color:azure;
    font-size: 1.5rem;
    text-decoration: none;
    border-radius: 5%;
    margin: 0.5rem;
  }

  div.list-group > a > div.list-group-item:hover {
    background-color: rgb(1, 1, 22);
  }

  div.list-group > a > div.list-group-item.active {
    background-color: var(--font-color);
  }

  div.list-group > a > div.list-group-item.active > div {
    font-size: 1.1rem;
    font-weight:bold ;
    color:rgb(1, 1, 22);
    /* margin-top: 0;
    margin-bottom: 0; */
  }
  
  div.list-group > a > div.list-group-item > div{
    color:var(--font-color);
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    font-size: 1.5rem;
    font-weight:bolder ;
    width: 80%;
  }
  
  #footer-row {
    display: flex;
    flex-direction: column;
    height: max-content;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  
  
  
  /*************************************************************************************************/
  /***********Phone Screen Layout***************/
  @media screen and (max-width:480px) {
    *{
      font-size: 1rem;
      overflow: auto;
    }
  
    .container {
      padding: .5rem;
    }
  
    #header-row {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      flex-wrap: wrap;
      width: 94vw;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }
  
    #header-row > div.Name-Col {
      display: flex;
      flex-direction: row;
      flex-basis: 40%;
      margin-right: auto;
      margin-left: 0;
  
    }
  
    #header-row > div.Name-Col > h1{
      font-size: 1.1rem;
    }
  
    #header-row > div.Search-Col {
      display: flex;
      flex-direction: row;
      flex-basis: 25%;
      margin-left: auto;
      margin-right: 1rem;
      margin-top: 0;
    }
  
    #header-row > div.Profile-Col {
      display: flex;
      flex-direction: row;
      flex-basis: 20%;
      margin-right: 0;
      margin-left: 0;
    }
  
    #body-row {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
    }
  
    #body-row > div.nav-col {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      width: 100%;
      margin-top:0rem;
    }
  
    #body-row > div.nav-col > nav {
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      height: fit-content;
    } 
  
    #body-row > div.nav-col > nav > div.list-group{
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex-direction: row;
      background-color: var(--background-color);
      padding: 0rem;
      margin-left: auto;
      margin-right: auto;
    }
  
    div.list-group > a > div.list-group-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-width: fit-content;
      min-height: fit-content;
      background-color: rgb(1, 1, 29);
      border-radius: 20%;
      padding: 0rem;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      overflow: visible;
    }

    div.list-group > a > div.list-group-item > div{
      font-size: 1rem;
      align-items: center;
      justify-content: center;
    }

    div.list-group > a > div.list-group-item.active {
      height: fit-content;
  }
  
    #body-row > div.content-col {
      display: flex;  
      flex-direction: column;
      flex-basis: 100%;
      width: 100%;
    }
  
  }
  /**************************/
  /*************************************************************************************************/
  