.add-cyber-button {
    display: flex;
    justify-content: center;
    margin-right: 0;
    margin-left: auto;
    margin-top: 0;
}

button {
    cursor: pointer;
    margin: 0.2rem;
    padding: .5rem;
    border-style: hidden;
    border-radius: 2em;
    cursor: pointer;
    background-color: rgba(5, 127, 158, 0.87);
    color: white;
    height: fit-content;
    width: fit-content;
    font-size: 1.5rem;
}

button:hover {
    background-color: rgb(1, 1, 22);
}

/*for the loading animation*/
.content-col > svg {
    margin-top: 0;
    margin-right: auto;
    margin-left:auto;
    margin-bottom: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}


.outer-table-wrapper {
    display: flex;
    margin-top: 0px;
    margin-bottom: auto;
    padding: 10px;
    border-radius: 10px;
    max-height: 62dvh;
    overflow: hidden;
    /* height: fit-content; */
    /* background-color: black; */
}


div.table-wrapper {
    width: 100%;
    background-color: rgb(3, 3, 63);
    padding: 5px;
    overflow: auto;
    scrollbar-width: thin;
    height: 62dvh; 
    border-radius: 10px;
  }

table {
    width: 100%;
    border-collapse: collapse;
    
    /* overflow: hidden; */
  }

caption > h2 {
  padding: 0;
  margin: 0;
}


th, td {
    height: 1.5rem;
    padding: .5rem;
}

th {
    position: sticky;
    top: 0px;
    text-align: left;
    font-size: 1.2rem;
}

th:after{
  content:'';
  position:absolute;
  left: 0;
  bottom: 0;
  width:100%;
  border-bottom: 3px solid white;
}

thead {
    position: sticky;
    top: 0px;
    background-color: rgb(1, 1, 22);
}

tbody > tr {
    border-top: 0px;
    border-bottom: 2px solid black;
    border-color: rgb(0, 0, 5);
    overflow: hidden;
  }

  tbody > tr:nth-child(even) {
    background-color: rgb(2, 2, 44);
  }


  @media screen and (max-width: 480px) {
    div.content-col > div{
        display: flex;  
        flex-direction: column;
        flex-basis: 100%;
      }

      button {
        height: 2rem;
        font-size: 1rem;
        margin: .3rem;
        padding: .3rem;
        overflow: hidden;
      }

      th, td {
        height: 1.5rem;
        padding: 0.5rem;
        font-size: 1rem;
    }

    th {
        font-weight: lighter;
        font-size: 1rem;

    }
  }

